import IntroLandingPage from "../../components/IntroLandingPage";
import MailChimpForm from "../../components/MailChimpForm";
import LandingFooter from "../../layout/LandingFooter";
import ParseHTML from "../../utils/parser";
import { Helmet } from 'react-helmet-async';
import GMap from "../../components/GoogleMapsOfDomino"

import logo from "../../../img/logo.svg";

const ShowroomSchedulePage = () => {

    const styles = {
        entryContentP: {
            letterSpacing: '0.25em',
            lineHeight: '1.1',
            marginBottom: '1.1em',
            maxWidth: '60rem',
        },
        h2: {
            fontWeight: 600,
            lineHeight: '1.1',
            marginBottom: '.8em',
            marginTop: '1.5em',
            fontSize: '4.2rem', // Aggiunta la virgola qui
        },

        h1: {
            fontSize: '5rem',
            fontWeight: 600,
            lineHeight: 1.1,
            marginBottom: '.5em',
            textAlign: 'center',
        },
    };

    return (
    <main class="page-job">
        <IntroLandingPage />
         <section className="max-w-site" style={{ display: 'flex', justifyContent: 'center', paddingBottom: '100px' }} >
            <h1 style={styles.h1} >{  <ParseHTML id={ "showroomschedule.pageTitle" } />}</h1>
         </section>
          <section class="px-4 entry-content" >
            <div className="grid size_5-6 max-w-site " style={{ gridColumnGap: '1%' }}>
                <div>
                    <h2 className="h2" style={styles.h2} ><ParseHTML id={ "showroomschedule.subTitle1"} /></h2>
                    <p  className="p-large" style={styles.entriContentP} ><b><ParseHTML id={"showroomschedule.text1"}/></b></p>
                    <p  className="p-large" style={styles.entriContentP}  ><ParseHTML id={"showroomschedule.text2"}/></p>
                    <p  className="p-large" style={styles.entriContentP} ><ParseHTML id={"showroomschedule.text3"}/></p>
                    <p  className="p-large" style={styles.entriContentP}  ><ParseHTML id={"showroomschedule.text4"}/></p>
                </div>
                <div className="container-form" >
                    <div className="inner-form">
                        <div className="form-group">
                            <iframe src="https://domino-design.us14.list-manage.com/subscribe/post?u=7052fc8e48ac472fc9dd8ba84&amp;id=0b26e6a6f6&amp;f_id=003b5ae0f0"
                            title="Appuntamenti Showroom"
                            sandbox="allow-scripts allow-top-navigation allow-same-origin allow-forms"
                            style={{  height: '285vh', width: '100%',overflow: 'hidden' ,border: 'none'}}
                            />
                        </div>
                    </div>
                </div>
            </div>
          </section>
          <section className="px-4 mb-8 bg-gradient">
            <div className="max-w-site t-align-center py-7">
              <h2 className="m0" style={styles.h2} ><ParseHTML id={"showroomschedule.plusMainTitle"} /></h2>
            </div>
          </section>
          <section className="px-4 mb-8 entry-content">
            <div className="grid size_3-3-3-3 t-align-center max-w-site" style={{ gridTemplateColumns: 'repeat(3, 1fr)' }} >
            <div>
                <h3 className="h3" ><b><ParseHTML id={"showroomschedule.plus1Title"} /></b></h3>
                <p className="p-large"><ParseHTML id={"showroomschedule.plus1Text"} /></p>
            </div>
            <div>
                <h3 className="h3" ><b><ParseHTML id={"showroomschedule.plus2Title"} /></b></h3>
                <p className="p-large"><ParseHTML id={"showroomschedule.plus2Text"} /></p>
            </div>
            <div>
                <h3 className="h3"  ><b><ParseHTML id={"showroomschedule.plus3Title"} /></b></h3>
                <p className="p-large"><ParseHTML id={"showroomschedule.plus3Text"}/></p>
              </div>
            </div>
          </section>
           <section className="px-4 mb-8">
            <div className="max-w-site t-align-center">
                <h2 className="m0" style={styles.h2} ><ParseHTML id={"showroomschedule.mapMainTitle"} /></h2>
            </div>
          </section>
           < GMap className="max-w-site" style={{ display: 'flex', justifyContent: 'center' }} />
          <LandingFooter />
        </main>

    );
};

export default ShowroomSchedulePage;